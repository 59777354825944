var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.list,
          rowKey: "id",
          pagination: _vm.pagination
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "images",
            fn: function(row) {
              return _c(
                "div",
                {
                  staticStyle: {
                    width: "50px",
                    height: "50px",
                    display: "flex",
                    "justify-content": "center",
                    "align-items": "center"
                  }
                },
                [
                  _c("img", {
                    staticStyle: { "max-width": "50px", "max-height": "50px" },
                    attrs: { src: row.showImageUrl },
                    on: {
                      click: function($event) {
                        return _vm.previewImg(row)
                      }
                    }
                  })
                ]
              )
            }
          },
          {
            key: "name",
            fn: function(text) {
              return _c("a", {}, [_vm._v(_vm._s(text))])
            }
          },
          {
            key: "setup",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  row.confirmVersionName == null ||
                  row.confirmVersionName.length == 0
                    ? _c(
                        "a",
                        {
                          staticStyle: { "margin-left": "5px" },
                          on: {
                            click: function($event) {
                              return _vm.customClick(row.ratingSpliderId)
                            }
                          }
                        },
                        [_vm._v("自定义确认")]
                      )
                    : _vm._e(),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "确定要删除此项么？",
                        "ok-text": "确认",
                        "cancel-text": "取消"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.remove(row.spliderId)
                        }
                      }
                    },
                    [
                      row.confirmVersionName == null ||
                      row.confirmVersionName.length == 0
                        ? _c("a", { staticStyle: { "margin-left": "5px" } }, [
                            _vm._v("删除")
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c(
        "a-modal",
        {
          attrs: {
            title: "自定义确认",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.cancelCustomModal, ok: _vm.confirmCustomModal },
          model: {
            value: _vm.customModal,
            callback: function($$v) {
              _vm.customModal = $$v
            },
            expression: "customModal"
          }
        },
        [
          _c("a-input", {
            attrs: { placeholder: "自定义确认名称" },
            model: {
              value: _vm.customValue,
              callback: function($$v) {
                _vm.customValue = $$v
              },
              expression: "customValue"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <a-modal
    v-model="pushModal"
    :width='800'
    title="上传"
    ok-text="确认"
    cancel-text="取消"
    @cancel="cancelPushModal"
    @ok="confirmPushModal"
  >
    <div class="push-modal">
      <div>
        <!-- 选择大币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoin"
          @search="coinSearch"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="curItem.coinKindId"
          placeholder="请选择大币种"
        >
          <a-select-option
            v-for="item of coinList"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindName }}</a-select-option>
        </a-select>
        <!-- 选择小币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoinItem"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 220px"
          v-model="curItem.coinKindItemId"
          placeholder="请选择小币种"
        >
          <a-select-option
            v-for="item of coinItemList"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          @change="changeVersion"
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 260px"
          v-model="curItem.coinKindItemVersionId"
          :dropdownMenuStyle="{'max-height': '500px'}"
          placeholder="版别"
        >
          <a-select-option
            v-for="item of versionList"
            :key="item.id"
            :value="item.id"
          >{{ `${item.coinKindVersionName}` }}</a-select-option>
        </a-select>
      </div>
      <div class="images">
        <div class="images-title">
          <div>正</div>
          <div>反</div>
        </div>
        <a-upload
          :key="imgKey"
          :action="ip + '/file/upload'"
          list-type="picture-card"
          :file-list="coverList"
          multiple
          @preview="handlePreview"
          @change="handleChangeCoverList"
        >
          <div>
            <a-icon type="plus" />
            <div class="ant-upload-text">上传图片</div>
          </div>
        </a-upload>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from "vuex";
import untils from "@/untils";
export default {
  data() {
    return {
      untils,
      pushModal: false,
      curItem: {},
      coverList: [],
      imgKey: "",
      coinList: [],
      coinItemList: [],
      versionList: [],
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  watch: {
    pushModal() {
      if (!this.pushModal) {
        this.coverList = [];
        this.curItem = {};
      }
    },
  },
  methods: {
    async open() {
      this.pushModal = true;
    },
    handleChangeCoverList({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.coverList = fileList;
    },
    handlePreview(file) {
      const picturesList = this.coverList.map((row) => {
        return row.url;
      });
      if (picturesList && picturesList.length > 0) {
        this.$previewImg({
          list: picturesList.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),
          current: this.coverList.findIndex(el => el.uid === file.uid),
          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
    // 大币种改变
    async changeCoin() {
      delete this.curItem.coinKindItemId;
      delete this.curItem.coinKindItemName;
      delete this.curItem.coinKindItemVersionId;
      delete this.curItem.coinKindItemVersionName;
      if (!this.curItem.coinKindId) return;
      const coin = this.coinList.find((el) => {
        return el.id == this.curItem.coinKindId;
      });
      this.$set(this.curItem, "coinSid", coin.sid);
      this.$set(this.curItem, "coinKindName", coin.coinKindName);
      await this.coinItemSearch();
    },
    //  选择小币种
    changeCoinItem() {
      delete this.curItem.coinKindItemVersionId;
      delete this.curItem.coinKindItemVersionName;
      if (!this.curItem.coinKindItemId) return;
      const coinItem = this.coinItemList.find((el) => {
        return el.id == this.curItem.coinKindItemId;
      });
      this.curItem.coinKindItemName = coinItem.coinKindItemName;
      this.versionList = coinItem.coinItemVersionList;
    },
    // 版别改变
    changeVersion() {
      if (!this.curItem.coinKindItemVersionId) return;
      const version = this.versionList.find((el) => {
        return el.id == this.curItem.coinKindItemVersionId;
      });
      this.curItem.coinKindItemVersionName = version.coinKindVersionName;
    },

    // 搜索，大币种搜索
    async coinSearch(str) {
      if (!str) return;
      const res = await this.axios("/dq_admin/kind/list", {
        params: { coinName: str },
      });
      if (res.status != "200") return;
      const { records } = res.data;
      this.coinList = records;
    },

    // 搜索小币种 +版别
    async coinItemSearch() {
      const res = await this.axios("/dq_admin/kind/getItemAndVersionNew", {
        params: { sid: this.curItem.coinSid },
      });
      const { coinItem } = res.data;
      this.coinItemList = coinItem;
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    async confirmPushModal() {
      const {
        coinKindId,
        coinKindName,
        coinKindItemId,
        coinKindItemName,
        coinKindItemVersionId,
        coinKindItemVersionName,
      } = this.curItem;
      const pushImages = [];
      let count = -1;
      this.coverList.forEach((el, index) => {
        if (index % 2 == 0) {
          count++;
          pushImages[count] = [];
        }
        pushImages[count].push(el.url);
      });
      const coverImages = pushImages.map((el) => {
        return el.join(",");
      });
      const res = await this.axios.post("/dq_admin/ratingSplider/pushAsyncMarkByManual", {
        coinKindId,
        coinKindName,
        coinKindItemId,
        coinKindItemName,
        coinKindItemVersionId,
        coinKindItemVersionName,
        coverImages,
      });
      if (res.status != 200) return;
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
      this.$emit("success");
    },
  },
};
</script>

<style lang="scss" scoped>
.images {
  width: 230px;
  margin-top: 10px;
  .images-title {
    display: flex;
    margin-bottom: 10px;
    div {
      flex: 1;
      text-align: center;
      margin-right: 10px;
    }
  }
}
</style>
<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="list"
      rowKey="id"
      @change="changePage"
      :pagination="pagination"
    >
      <!-- 正面图 -->
      <div
        slot="images"
        slot-scope="row"
        style="width:50px;height:50px;display:flex;justify-content:center;align-items:center;"
      >
        <img
          @click="previewImg(row)"
          style='max-width:50px;max-height:50px;'
          :src='row.showImageUrl'
        />
      </div>
      <a
        slot="name"
        slot-scope="text"
      >{{ text }}</a>
      <div
        slot="setup"
        slot-scope="row"
      >
        <a
          v-if="row.confirmVersionName == null || row.confirmVersionName.length == 0"
          style="margin-left: 5px"
          @click="customClick(row.ratingSpliderId)"
        >自定义确认</a>

        <a-popconfirm
          title="确定要删除此项么？"
          ok-text="确认"
          cancel-text="取消"
          @confirm="remove(row.spliderId)"
        >
          <a
            v-if="row.confirmVersionName == null || row.confirmVersionName.length == 0"
            style="margin-left: 5px"
          >删除</a>
        </a-popconfirm>
      </div>
    </a-table>
    <a-modal
      v-model="customModal"
      title="自定义确认"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancelCustomModal"
      @ok="confirmCustomModal"
    >
      <a-input
        placeholder='自定义确认名称'
        v-model="customValue"
      ></a-input>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: ["list", "loading", "pagination"],
  data() {
    return {
      detail: "",
      columns: [
        { title: "Id", dataIndex: "spliderId", align: "center" },
        { title: "大币种", align: "center", dataIndex: "itemCoinName" },
        { title: "小币种", align: "center", dataIndex: "itemCoinItemName" },
        { title: "评级-版别", align: "center", dataIndex: "itemCoinVersionName" },

        {
          title: "正反图",
          align: "center",
          scopedSlots: { customRender: "images" },
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      ratingSpliderId: "",
      customValue: "",
      customModal: false,
    };
  },
  methods: {
    // 确认
    confirmCustomModal() {
      this.confirmVersion(this.ratingSpliderId, this.customValue);
      this.customValue = "";
      this.customModal = false;
    },
    // 自定义确认点击
    customClick(id) {
      this.ratingSpliderId = id;
      this.customModal = true;
    },
    // 取消
    cancelCustomModal() {
      this.customValue = "";
    },
    confirmVersion(id, name) {
      this.axios("/dq_admin/ratingSpliderUserAnswer/confirmVersion", {
        params: { id: id, confirmVersionName: name },
      }).then((res) => {
        this.$message.success("确认成功");
        this.$emit("success");
      });
    },
    remove(id) {
      this.axios("/dq_admin/ratingSplider/delById", {
        params: { id: id },
      }).then((res) => {
        this.$message.success("删除成功");
        this.$emit("success");
      });
    },
    // 切换分页
    changePage(page) {
      this.$emit("changePage", page);
    },
    previewImg(row) {
      const list = [];
      if (row.showImageUrl) {
        list.push({
          img_url: row.showImageUrl,
        });
      }
      if (row.revealImageUrl) {
        list.push({
          img_url: row.revealImageUrl,
        });
      }
      this.$previewImg({
        list: list,
        baseImgField: "img_url",
        showMute: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-content {
  max-width: 600px;
}
.modal {
  input {
    margin-bottom: 5px;
  }
}

</style>